.loading {
    margin: 1rem auto;
    width: 150px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  
  .loading--section {
    left: 0;
    position: fixed;
    right: 0;
    text-align: center;
    top: 40vh;
    max-width: 160px;
    margin: 0 auto;
  }
  
  .loader {
    background-color: #d9d9e1;
    border-radius: 2px;
    height: 3px;
    overflow: hidden;
    position: relative;
    margin-top: 1rem;
    width: 100%;
  }
  
  .loader::after {
    animation: loading 1.5s infinite ease;
    background-color: #574eeb;
    border-radius: 2px;
    content: "";
    height: 3px;
    position: absolute;
    width: 80px;
  }
  
  @keyframes loading {
    0%,
    100% {
      transform: translateX(-120px);
    }
  
    50% {
      transform: translateX(40px);
    }
  }